<template>
<div>
  <div v-if="route_contents.length==0" class="text-danger">
    {{$t('learning_path.contents_missing')}}
  </div>
  <template v-else>
    <draggable :list="route_contents" :move="detectMove" :disabled="isReport">
      <div 
        v-for="(data) in route_contents"
        :key="data.id"
        :class="[isReport?'':'ns-resize','d-flex justify-content-start align-items-center mt-1']"
      >
        <div class="mr-1">
          <svg v-if="!isReport" class="mr-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(109, 107, 123, 1);transform: ;msFilter:;"><path d="m7 17 5 5 5-5h-4V7h4l-5-5-5 5h4v10z"></path></svg>          
          <b-avatar
            size="40"
            :src="data.icon_url"
            rounded
            variant="light"
          />
        </div>
        <div class="profile-user-info">
          <h6 class="mb-0">
            {{ data.title }}
          </h6>
          <small class="text-muted">{{ $t('content_types.'+data.content_type) }}</small>
        </div>
        <div class="profile-star ml-auto">
          <b-button
              v-b-tooltip.hover.top="$t('learning_path.remove')"
              variant="light"
              type="button"
              @click="$emit('toggle-content-in-list', data)"
            >
            <feather-icon
              icon="XIcon"
              size="16"
            />
            </b-button>
        </div>
      </div>
    </draggable>

    
  </template>
</div>
</template>

<script>
import draggable from 'vuedraggable'

import {
    BAvatar, BButton, VBTooltip
} from 'bootstrap-vue'

export default {
  components:{
    BAvatar,
    BButton,
    VBTooltip,
    draggable
  },
  props:{
    route_contents: {
      type: Array,
      required: true
    },
    isReport:{
      type: Boolean,
      default: false,
      required: false
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods:{
    detectMove(evt){
       //// console.log("detectMove", evt, this.route_contents)
       if(!this.isReport){
         this.$store.commit('learningPaths/setRouteContents', this.route_contents)
       }

    }
  }
}
</script>

<style>
.ns-resize {cursor: ns-resize;}
</style>