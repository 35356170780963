<template>
  <div class="py-1">
    <b-list-group class="ml-1 mr-1">
      <b-list-group-item 
        button
        @click="$emit('contents-hierarchy-selected', [])">
          {{$t('reports.see_all_content')}}
        </b-list-group-item>
    </b-list-group>
    <app-collapse>
      <app-collapse-item title="" v-for="section in contentsHierarchy" :key="section.id">
          <template v-slot:header >
            <span class="d-flex justify-start align-start">
              <b-img
                :src="section.icon"
                blank-color="#ccc"
                width="28"
                alt="placeholder"
                class="mr-1"
              />
              <span style="font-weight: 500">
                {{section.name}}
              </span>
            </span>
          </template>
          <app-collapse>
            <app-collapse-item title="" v-for="cat in section.subcategories" :key="cat.id">
                <template v-slot:header>
                  <span class="d-flex justify-start align-start">
                    <b-img
                      :src="cat.icon"
                      blank-color="#ccc"
                      width="30"
                      alt="placeholder"
                      class="mr-1 p-25"
                      :style="{backgroundColor: section.rgb_box, borderRadius: '5px' }"
                    />
                    <span style="font-weight: 500">
                      {{cat.name}}
                    </span>
                  </span>
                </template>
                <b-list-group >
                  <b-list-group-item 
                    v-for="subcat in cat.subcategories" :key="subcat.id"
                    button
                    @click="handleClick(subcat.contents)">
                    <span class="d-flex justify-start align-start">
                      <b-img
                        :src="subcat.icon"
                        blank-color="#ccc"
                        width="30"
                        alt="placeholder"
                        class="mr-1 p-25"
                        :style="{backgroundColor: section.rgb_box, borderRadius: '5px' }"
                      />
                      {{subcat.name}}
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </app-collapse-item>
          </app-collapse>
        </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import { BMedia, BImg, BCardText, BListGroup, BListGroupItem } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'


export default {
  components: {
    BMedia,
    BImg,
    BCardText,
    BListGroup, BListGroupItem,

    AppCollapse,
    AppCollapseItem,

  },
  props:{
    contentsHierarchy:{
      type: Array,
      required: true
    }
  },
  methods: {
    handleClick(contents) {
      this.$emit('contents-hierarchy-selected', contents);
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style>
.justify-start{justify-content: flex-start;}
.align-start{ align-items: flex-start}
</style>